<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-06-12 14:07:19
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2025-01-14 15:18:48
 * @Description: 
-->
<template>
	<div class="set_page">
		<div class="item" v-for="(item,index) in functionSwitchData" v-bind:key="index">
			<div class="text_1">{{ item.label }}</div>
			<a-switch default-checked  :checked="item.config_value" :data-name="index" @change="onChange" />
			<div class="text_2">{{ item.tips }}</div>
		</div>
	</div>
</template>

<script>
import {setConfig, getConfig} from '@/api/personalization'
export default {
	data() {
		return {
			functionSwitchData:{
				change_room_sms:{
					config_value:false,//开关状态
					label:'转台发送短信选择：',
					tips:'开启后，转台时可选择是否发送短信给预订人。',
				},
				cancel_order_sms:{
					config_value:false,//开关状态
					label:'取消发送短信选择：',
					tips:'开启后，取消预订时可选择是否发送短信给预订人。',
				},
				// edit_order_sms:{
				// 	config_value:false,//开关状态
				// 	label:'修改预订发送短信选择：',
				// 	tips:'开启后，取消预订时可选择是否发送短信给预订人。',
				// },
				disable_cancel_change:{
					config_value:false,//开关状态
					label:'订单无法取消和修改转台：',
					tips:'开启后，电脑网页工作台只可以查看其他人预订的订单，无法取消和修改转台。隐藏除前台自己预订的订单的转台按钮。',
				},
				// multiple_meal_time:{
				// 	config_value:false,//开关状态
				// 	label:'多餐段设置：',
				// 	tips:'小程序端和电脑端增加开启的对应餐段，早茶餐段分割时间为6:00-9:30每半小时，夜宵为21:30-凌晨3:00每半小时。',
				// },
				holiday_twice_meal_time:{
					config_value:false,//开关状态
					label:'节假日二轮餐段设置：',
					tips:'开启后，节假日的午餐晚餐各变为第一餐段和第二餐段。',
				},
				cloud_contact_list:{
					config_value:false,//开关状态
					label:'关闭预订人云通讯录匹配：',
					tips:'开启后，小程序预订下单时预订人处将不再匹配云通讯录信息。',
				},
				must_need_phone:{
					config_value:false,//开关状态
					label:'手机号是否必填：',
					tips:'开启后，小程序预订信息手机号为必填内容。',
				},
				hide_room_type:{
					config_value:false,//开关状态
					label:'邀请函不显示桌型：',
					tips:'开启后，将不在邀请函显示餐位的桌型。',
				},
				invitation_hide_dining_type:{
					config_value:false,//开关状态
					label:'邀请函不显示餐段名：',
					tips:'开启后，小程序邀请函将不显示餐段名。',
				},
				invitation_hide_arrive_time:{
					config_value:false,//开关状态
					label:'邀请函不显示到店时间：',
					tips:'开启后，小程序邀请函将不显示预计到店时间。',
				},
				invitation_show_chinese_calendar:{
					config_value:false,//开关状态
					label:'邀请函是否显示农历：',
					tips:'开启后，小程序邀请函将显示预订的农历日期。',
				},
				invitation_share_show_date:{
					config_value:false,//开关状态
					label:'邀请函封面图显示日期时间：',
					tips:'开启后，小程序邀请函转发时封面图将显示预订就餐的日期和时间。（需上传门店Logo才可生效）。',
				},
				invitation_hide_arrive_count:{
					config_value:false,//开关状态
					label:'邀请函不显示预订人数：',
					tips:'开启后，将不在邀请函上显示预订人数。',
				},
				staple_show_all_rooms:{
					config_value:false,//开关状态
					label:'电子预订本是否显示全部：',
					tips:'开启后，电子预订本显示【全部】分类展示门店所有餐位情况。',
				},
				staple_show_small_card:{
					config_value:false,//开关状态
					label:'电子预订本小卡片模式：',
					tips:'开启后，每次进入电子预订本将默认展示小卡片模式。',
				},
				show_order_dishes:{
					config_value:false,//开关状态
					label:'邀请函预点菜：',
					tips:'开启后，小程序预订下单的预点菜功能将被打开。',
				},
				manager_show_store_customers:{
					config_value:false,//开关状态
					label:'店长可见门店客户：',
					tips:'开启后，店长权限的用户可在小程序中查看门店客户。',
				}
			}
		}
	},
	created() {
		this.getConfig()
	},
	methods: {
		/**
		 * @description: 获取个性化设置列表
		 * @return {*}
		 */
		async getConfig() {
			this.$store.dispatch('loading/startContentLoading')
			const {code, data = {}} = await getConfig()
			if (code === 0) {
				//初始化列表
				const isNonZeroValue = (value) =>{
					const num = parseInt(value || '0');
					return num!== 0;
				}
				const specialConfigHandlers = {
					show_order_dishes: (data, key) => isNonZeroValue(data[key] || '1'),
					change_room_sms: (data, key) => data[key] / 1!== 0
				};
				for(var config_key in this.functionSwitchData){
					let item = this.functionSwitchData[config_key];
					 item.config_value = specialConfigHandlers[config_key] 
					? specialConfigHandlers[config_key](data, config_key)
						: isNonZeroValue(data[config_key]);
				};
			}
			this.$store.dispatch('loading/endContentLoading')
		},
		/**
		 * @description: 设置开关
		 * @return {*}
		 * @param {*} checked
		 * @param {*} ev
		 */
		async onChange(checked, ev) {
			const config_name = ev.target.dataset.name;
			this.functionSwitchData[config_name].config_value = checked;
			const {code} = await setConfig({
				config_name,
				config_value: checked ? 1 : 0
			})
			if (code === 0) {
				this.$message.success('设置成功！')
			}
		}
	}
}
</script>

<style scoped lang="less">
.set_page {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 0;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
}
.set_page > .item {
	display: flex;
	flex-direction: row;
	margin-top: 15px;
	align-items: center;
}
.text_1 {
	width: 220px;
	font-weight: 500;
	font-size: 16px;
	color: #333333;
	margin-right: 10px;
	text-align: right;
}
.text_2 {
	font-weight: 400;
	font-size: 14px;
	color: #666666;
	margin-left: 10px;
}
</style>
